import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DOMAIN_ADDRESS } from '../const';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: Http) { }

  

  getOwnerList() {
    return this.http.get(DOMAIN_ADDRESS + 'loadOwnerList')
    .map(response => {
      const Response = response.json();
      return Response;
    });
  }

  OwnerListLoad() {
    return this.http.get(DOMAIN_ADDRESS + 'getOwnerListByCategory')
    .map(response => {
      const Response = response.json();
      return Response;
    });
  }

  createNewCategory(category: any[]) {
    return this.http.post(DOMAIN_ADDRESS + 'NewInsertCategory', category)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  getCategoryList(data) {    
    return this.http.post(DOMAIN_ADDRESS + `GetCategoryList`,data)
        .map(response => {
          const Response = response.json();
          return Response;
        });
    }

    deleteCategory(categoryID: string) {
      return this.http.get(DOMAIN_ADDRESS + 'CategoryDelete/' + categoryID, '')
        .map(response => {
          const Response = response.json();
          return Response;
        });
    }

    
    updateCategory(category: any[]) {
    return this.http.post(DOMAIN_ADDRESS + 'UpdateCategory', category)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }
  
}
