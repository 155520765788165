import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOMAIN_ADDRESS } from '../../const';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  userDetails: any = '';
  userImage: any = './assets/images/nouser.png';
  userName: any;
  userType: any;
  companyID: any;
  companyName: any;
  companyImage: any;
  userEmailID: any;
  branchID: any;
  branchName: any;
  hideHearder: boolean = false;
  isShowProfile: boolean = false;

  constructor(private modalService: NgbModal, public route: Router, private _router: ActivatedRoute) {
    var retailorlog = "retailorLogin";
    var isretailorloginUrl = this.route.url.includes(retailorlog);
    this.userType = 'Retailer';
    this.userImage = 'assets/images/nouser.png';
    if (isretailorloginUrl) {
      this.hideHearder = true;
      this.isShowProfile = true;
      var company = this.route.url.split('/');
      this.companyName = decodeURIComponent(company[4]);
    }else if (this.route.url === '/productOrder') {
      this.hideHearder = true;
      this.isShowProfile = false;
    }else if (this.route.url === '/productConfirm') {
      this.hideHearder = true;
      this.isShowProfile = false;
    }
    if(!this.hideHearder){
      // this.userDetails = JSON.parse(localStorage.getItem('infoUserKannadi'));
      // this.companyID = this.userDetails[0].CompanyID;
      // this.userName = this.userDetails[0].userName;
      // this.userEmailID = this.userDetails[0].userEmail;
      // this.userType = this.userDetails[0].userType;
      // this.companyName = this.userDetails[0].CompanyName;
      // this.branchID = this.userDetails[0].BranchID;
      // this.branchName = this.userDetails[0].BranchName;
      // if (this.userDetails[0].userPhoto != '' && this.userDetails[0].userPhoto != undefined) {
      //   this.userImage = DOMAIN_ADDRESS + this.userDetails[0].userPhoto;
      // } else {
      //   this.userImage = 'assets/images/nouser.png';
      // }
      // if (this.userDetails[0].CompanyImage != '' && this.userDetails[0].CompanyImage != undefined) {
      //   this.companyImage = DOMAIN_ADDRESS + this.userDetails[0].CompanyImage;
      // } else {
      //   this.companyImage = 'assets/images/nouser.png';
      // }
    }else{
      this.userDetails = JSON.parse(localStorage.getItem('infoRetailorKannadi'));
      if(this.userDetails != null){
        this.userType = 'Retailer';
        this.userName = this.userDetails[0].CustomerName;
        this.userEmailID = this.userDetails[0].CustomerEmail;
        this.companyID = this.userDetails[0].CompanyID;
        this.companyName = this.userDetails[0].CompanyName;
        if (this.userDetails[0].CompanyImage != '' && this.userDetails[0].CompanyImage != undefined) {
          this.companyImage = DOMAIN_ADDRESS + this.userDetails[0].CompanyImage;
        } else {
          this.companyImage = 'assets/images/nouser.png';
        }
        this.isShowProfile = false;
      }
    }
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  logout(){
    if(!this.hideHearder){
      localStorage.clear();
      this.route.navigate(['/authentication/login']);
    }else{
      let randomID = JSON.parse(localStorage.getItem('infoKannadiRondomID'));
      localStorage.clear();
      this.route.navigate(['/retailorLogin/'+randomID+'/'+this.companyID+'/'+this.companyName]);
    }
  }

  goToProfile(){
    this.route.navigate(['/userprofile']);
  }

  ngAfterViewInit() {}
}
