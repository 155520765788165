// tslint:disable-next-line:no-shadowed-variable
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { CategoryService } from './category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categoryForm: FormGroup;
  apicall: any;
  ownerID: any = '';
  ownerIDForlist: any = '';
  companyID: any = '';
  userDetails: any = '';
  getAllCategory: any = '';
  showCategoryForm: Boolean = false;
  isNewCategory: Boolean = false;
  ownerList: any = [];
  ownerNames: any = [];
  ShowCategoryList = true;

  limit: number = 0;
  modelCount: any = 0;
  page: any = 1;
  totalCountOfRecords: any = 0;
  CollectionPageSize: any = 0;
  showPagination:boolean = false;

  @ViewChild('successModal') private successModal: SwalComponent;
  @ViewChild('warningModal') private warningModal: SwalComponent;
  constructor(private categoryService: CategoryService) {
    this.categoryForm = new FormGroup({
        OwnerID: new FormControl(null),
        OwnerIDList: new FormControl(null),
        CategoryID: new FormControl(null),
        CategoryName: new FormControl(null),
    });
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('infoRig'));
    this.companyID = 0;
    this.loadOwnerList();
    this.loadOwners();
  }

  newCategoryForm() {
    this.showCategoryForm = !this.showCategoryForm;
    this.isNewCategory = true;
    this.categoryForm.reset();
  }

  closeForm() {
    this.showCategoryForm = false;
    this.categoryForm.reset();
  }


  ownerSelected()  {
    this.ownerIDForlist = this.categoryForm.value.OwnerIDList;
    this.loadAllCategory(this.ownerIDForlist);
  }
  
  loadOwnerList() {
    this.apicall = this.categoryService.getOwnerList();
    this.apicall.subscribe(
      (response) => {
        if (response.record_count > 0) {
          this.ownerList = response.records;
        }
      }
    );
  }

  loadOwners() {
    this.apicall = this.categoryService.OwnerListLoad();
    this.apicall.subscribe(
      (response) => {
        if (response.record_count > 0) {
          this.ownerNames = response.records;
        }
      }
    );
  }

  categoryEdit(categoryDetails) {
    this.showCategoryForm = true;
    this.isNewCategory = false;
    window.scrollTo(0,0);    
    this.categoryForm.patchValue({
      CategoryID: categoryDetails.CategoryID,
      CategoryName: categoryDetails.CategoryName,
      OwnerID: categoryDetails.OwnerID,
    });
  }

  categoryDelete(categoryDetails) {
    if(categoryDetails.CategoryName != 'Diesel' && categoryDetails.CategoryName != 'Petrol' && categoryDetails.CategoryName != 'Mess' && categoryDetails.CategoryName != 'Labour Salary' && categoryDetails.CategoryName != 'Others' && categoryDetails.CategoryName != 'Medical' ){
      this.apicall = this.categoryService.deleteCategory(categoryDetails.CategoryID);
      this.apicall.subscribe(
        (response) => {
          if (response.status === 'Success') {
            this.successModal.title = 'Category';
            this.successModal.text = 'Category Deleted SuccessFully.';
            this.successModal.show();
            this.loadAllCategory(this.ownerIDForlist);
          } else {
            this.warningModal.text = 'Cannot Delete Category .';
            this.warningModal.show();
  
          }
        }
      );

    }else{
      this.warningModal.text = 'Cannot Delete Default Category';
      this.warningModal.show();
    }
 
  }

  
  loadAllCategory(owner) {
    let data = {
      Limit: this.limit,
      IsUseLimit: 1,
      OwnerID: owner
    }    
    this.getAllCategory = this.categoryService.getCategoryList(data);  
    this.getAllCategory
      .subscribe(
        (response) => {
          this.getAllCategory = response;
          this.totalCountOfRecords = response.TotalCategoryCount;
          this.ShowCategoryList = false;
          if (this.totalCountOfRecords !== 0 ) {
            this.showPagination = true;
            const LastPage = this.totalCountOfRecords / 10;
            const LP = Math.ceil(LastPage);
            this.CollectionPageSize = LP * 10;
          } else {
            this.showPagination = false;
          }
        }
      );
  }

  onPageChange(page) {
    this.showCategoryForm = false;
    this.limit = (page * 10) - 10;
    this.modelCount = (page * 10) - 10;
    this.loadAllCategory(this.ownerIDForlist);
  }



  submitCategory() {
    const isownerSelected = this.categoryForm.value.OwnerID;
    const isCategoryNameSelected = (this.categoryForm.value.CategoryName);
    this.categoryForm.patchValue({
      OwnerID: isownerSelected,
      CategoryName: isCategoryNameSelected,
    });
    if(this.categoryForm.value.CategoryName != null && this.categoryForm.value.OwnerID != null ){
    if (this.categoryForm.value.CategoryID == null) {
      this.apicall = this.categoryService.createNewCategory(this.categoryForm.value);
      this.apicall.subscribe(
        (response) => {
          if (response.status === 'Success') {
            this.successModal.title = 'Category';
            this.successModal.text = 'Category Added SuccessFully.';
          this.successModal.show();
              this.loadAllCategory(this.ownerIDForlist);
            
          } else {
            this.warningModal.text = 'Cannot Create Category';
          this.warningModal.show();
          }
        }
      );     
    } else {
      this.apicall = this.categoryService.updateCategory(this.categoryForm.value);
      this.apicall.subscribe(
        (response) => {
          if (response.status === 'Success') {
            this.successModal.title = 'Category';
            this.successModal.text = 'Category Updated SuccessFully.';
          this.successModal.show();
            this.loadAllCategory(this.ownerIDForlist);
          } else {
            this.warningModal.text = 'Cannot Update Category';
          this.warningModal.show();

          }
        }
      );    
      
    }
  }
    else{
      this.warningModal.text = 'Fill All fields';
      this.warningModal.show();
    }
    // this.apicall.subscribe(
    //     (response) => {
    //       if (response.status === 'Success') {
    //         if (response.isExistCategory === 0) {
    //           this.successModal.show();
    //           // this.loadAllCategory(this.ownerIDForlist);
    //           this.categoryForm.reset();
    //           this.showCategoryForm = false;
    //         } else {
    //           // tslint:disable-next-line:no-shadowed-variable
    //           const CategoryName = decodeURIComponent(this.categoryForm.value.CategoryName);
    //           // tslint:disable-next-line:no-shadowed-variable
    //           const CategoryShortCode = decodeURIComponent(this.categoryForm.value.CategoryShortCode);
    //           this.categoryForm.patchValue({
    //             CategoryName: CategoryName,
    //             CategoryShortCode: CategoryShortCode
    //           });
    //           this.warningModal.text = 'Please check your entry. Category already exist.';
    //           this.warningModal.show();
    //         }
    //       } else {
    //         this.warningModal.show();
    //       }
    //     }
    //   );
  }

}
