import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DOMAIN_ADDRESS } from '../const';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CreateOwnerService {

  constructor(private http: Http) { }

  createOwner(data) {
    return this.http.post(DOMAIN_ADDRESS + `InsertNewOwner`,data)
      .map(response => {
        const Response = response.json();
        return Response;
      });

  }

  getAllOwnerList(data) {
    return this.http.post(DOMAIN_ADDRESS + `GetallOwner`,data)
      .map(response => {
        const Response = response.json();
        return Response;
      });

  }

  getStateList(data) {
    return this.http.post(DOMAIN_ADDRESS + 'GetStateList', data)
    .map(response => {
      const Response = response.json();
      return Response;
    });
  }

  getCityList(data) {
    return this.http.post(DOMAIN_ADDRESS + 'GetCityList', data)
    .map(response => {
      const Response = response.json();
      return Response;
    });
  }

  updateOwner(owner: any[]) {
    return this.http.post(DOMAIN_ADDRESS + 'UpdateOwner', owner)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  deleteOwner(data) {
    return this.http.post(DOMAIN_ADDRESS + 'DeleteOwner', data)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  deleteVehicleArray(data) {
    return this.http.post(DOMAIN_ADDRESS + 'DeleteVehicleSubscribe',data)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  uploadCreateOwnerImage(image) {
    return this.http.post(DOMAIN_ADDRESS + 'ajaximageweb.php', image)
    .map(response => {
    // console.log(response,'response');
      const Response = response.json();
      return Response;
    });
  }
}
