import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

import { CategoryComponent } from './category/category.component';
import { CreateOwnerComponent } from './create-owner/createowner.component';
// import { VehicleComponent } from './vehicle/vehicle.component';
// import { AdminAccessComponent } from './admin-access/admin-access.component';
// import { TransactionComponent } from './transaction/transaction.component';
import { OwnerDashboardComponent } from './ownerdashboard/ownerdashboard.component';
import { OtpLoginComponent } from './otpLogin/otpLogin.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';


export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  // {
  //   path: '',
  //   component: FullComponent,
  //   children: [
  //     { path: 'retailorLogin/:orderID/:companyID/:companyName', component: RetailorLoginComponent },
  //     { path: 'productOrder', component: ProductOrderComponent },
  //     { path: 'productConfirm', component: ProductOrderCheckoutComponent },
  //   ]
  // },
  // {
  //   path: '',
  //   component: NavigationComponent,
  //   children: [
  //     { path: 'retailorLogin/:orderID/:companyID/:companyName', component: RetailorLoginComponent },
  //     { path: 'productOrder', component: ProductOrderComponent },
  //     { path: 'productConfirm', component: ProductOrderCheckoutComponent },
  //   ]
  // },
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/dashboard/classic', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
   
      { path: 'category', component: CategoryComponent},
      { path: 'createowner', component: CreateOwnerComponent},
      // { path: 'vehicle', component: VehicleComponent},
      // { path: 'admin-access', component: AdminAccessComponent},
      // { path: 'transaction', component: TransactionComponent},
      { path: 'ownerdashboard', component: OwnerDashboardComponent},
      { path: 'otp', component: OtpLoginComponent},
      { path: 'cards', loadChildren: './cards/cards.module#CardsModule' },
      { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      { path: 'forms', loadChildren: './form/forms.module#FormModule' },
      { path: 'tables', loadChildren: './table/tables.module#TablesModule' },
      { path: 'charts', loadChildren: './charts/charts.module#ChartModule' },
      {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule'
      },
      { path: 'ecom', loadChildren: './ecommerce/ecom.module#EcomModule' },
      {
        path: 'timeline',
        loadChildren: './timeline/timeline.module#TimelineModule'
      },
      {
        path: 'extra-component',
        loadChildren:
          './extra-component/extra-component.module#ExtraComponentModule'
      },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
      { path: 'maps', loadChildren: './maps/maps.module#MapsModule' },
      {
        path: 'sample-pages',
        loadChildren: './sample-pages/sample-pages.module#SamplePagesModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
