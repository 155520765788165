import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = 'Master';
  showSubMenu = '';
  userDetails: any;
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
      // this.userDetails = JSON.parse(localStorage.getItem('infoUserKannadi'));
      // this.userDetails[0].showMenu = element;
      // localStorage.setItem('infoUserKannadi', JSON.stringify(this.userDetails));
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
      // this.userDetails = JSON.parse(localStorage.getItem('infoUserKannadi'));
      // this.userDetails[0].showSubMenu = element;
      // localStorage.setItem('infoUserKannadi', JSON.stringify(this.userDetails));
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // this.userDetails = JSON.parse(localStorage.getItem('infoUserKannadi'));
  }

  // End open close
  ngOnInit() {
    // this.userDetails = JSON.parse(localStorage.getItem('infoUserKannadi'));
    // this.showMenu = this.userDetails[0].showMenu;
    // this.showSubMenu = this.userDetails[0].showSubMenu;
    this.sidebarnavItems = ROUTES.filter(
      sidebarnavItem => this.getMenuList(sidebarnavItem));
  }

  getMenuList(sidebarnavItem) {
    // if (this.userDetails[0].userType === 'SuperAdmin') {
    //   if (sidebarnavItem.showRole === 'SuperAdmin') {
    //     return sidebarnavItem;
    //   }
    // } else if (this.userDetails[0].userType === 'Sales') {
    //   if (sidebarnavItem.showRole === 'Sales') {
    //     return sidebarnavItem;
    //   }
    // } else {
      if (sidebarnavItem.showRole === 'All') {
        return sidebarnavItem;
      }
    }
  }
// }
