import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DOMAIN_ADDRESS } from '../const';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class OwnerDashboardService {

  constructor(private http: Http) { }

  getAllVehicle(data) {
    return this.http.post(DOMAIN_ADDRESS + `GetAllVehicleDetails`,data)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  getVehicleDetails(data) {
    return this.http.post(DOMAIN_ADDRESS + `VehicleAdminList`,data)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

  EditAdminAccess(permID: string, post) {
    return this.http.post(DOMAIN_ADDRESS + `UpdateVehiclePermissionByID/`+ permID,post)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }
  
  // getAllAdminDetails(data) {
  //   return this.http.post(DOMAIN_ADDRESS + 'GetAdminAccess' ,data)
  //     .map(response => {
  //       const Response = response.json();
  //       return Response;
  //     });
  // }
}
