import { Component, OnInit, ViewChild,NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { otpLoginService } from './otpLogin.service';
import { DOMAIN_ADDRESS } from '../const';
@Component({
  selector: 'app-otpLogin',
  templateUrl: './otpLogin.component.html',
  styleUrls: ['./otpLogin.component.css']
})
export class OtpLoginComponent implements OnInit {
  ownerdashForm: FormGroup;
  apicall: any;
  getAllUserOTP: any;
  ownerName: any = '';
  selectedArrayData : any = []; 
  userDetails: any = '';
  userID: any = 0;
  companyID: any = '';
  ownerid: any = '';


  @ViewChild('successModal') private successModal: SwalComponent;
  @ViewChild('warningModal') private warningModal: SwalComponent;
  userType: any;
  constructor(private otpLoginService: otpLoginService) {
    this.ownerdashForm = new FormGroup({
      OwnerName: new FormControl(null),
      OwnerID: new FormControl(null),
      Access: new FormControl(null),
    });
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('infoRig'));
    this.companyID = 0;
    this.loadAllVehicle();
  }

  loadAllVehicle() {
    this.getAllUserOTP = this.otpLoginService.getAllOTP()
      .subscribe(
        (response) => {
          this.getAllUserOTP = response;
        }
      );
  }

  
  
}
