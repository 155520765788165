import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DOMAIN_ADDRESS } from '../const';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class otpLoginService {

  constructor(private http: Http) { }

  getAllOTP() {
    return this.http.get(DOMAIN_ADDRESS + `ShowAllOTP`)
      .map(response => {
        const Response = response.json();
        return Response;
      });
  }

 }
