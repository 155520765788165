import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class BlankComponent implements OnInit {
  constructor(public route: Router) {}

  ngOnInit() {
    if (this.route.url === '/') {
      this.route.navigate(['/authentication/login']);
    }
  }
}
