import { Component, OnInit, ViewChild,NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { OwnerDashboardService } from './ownerdashboard.service';
import { DOMAIN_ADDRESS } from '../const';
@Component({
  selector: 'app-createowner',
  templateUrl: './ownerdashboard.component.html',
  styleUrls: ['./ownerdashboard.component.css']
})
export class OwnerDashboardComponent implements OnInit {
  ownerdashForm: FormGroup;
  apicall: any;
  getAllVehicle: any;
  getVehicleDetails: any;
  VehicleListData: any = [];
  getAllAdminAccess: any;
  ownerName: any = '';
  ownerPhone: any = '';
  vehicleUnit: any = '';
  vehicleNumber: any = '';
  selectedIndex : any = '';
  adminPerm: any = '';
  selectedArrayData : any = []; 
  userDetails: any = '';
  showOwnerDashForm: Boolean = false;
  showAdminData: Boolean = false;
  userID: any = 0;
  companyID: any = '';
  ownerid: any = '';

  limit: number = 0;
  modelCount: any = 0;
  page: any = 1;
  totalCountOfRecords: any = 0;
  CollectionPageSize: any = 0;
  showPagination:boolean = false;

  @ViewChild('successModal') private successModal: SwalComponent;
  @ViewChild('warningModal') private warningModal: SwalComponent;
  userType: any;
  constructor(private ownerDashboardService: OwnerDashboardService) {
    this.ownerdashForm = new FormGroup({
      OwnerName: new FormControl(null),
      OwnerID: new FormControl(null),
      Access: new FormControl(null),
    });
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('infoRig'));
    this.companyID = 0;
    this.loadAllVehicle(this.userDetails[0].userID);
    // this.loadAllVehicleList(6);
    this.selectedIndex = -1;
    this.showAdminData = false;
  }

  closeForm() {
    this.showOwnerDashForm = false;
    this.showAdminData = false;
    window.scrollTo(0,0);
  }
  
  showdetails(i){

    this.vehicleUnit =  i.vechileName;
    this.vehicleNumber =  i.vechileNumber;

    let index = this.VehicleListData.indexOf(i);
    if(this.selectedIndex == index){
      this.selectedIndex = -1;
      this.showAdminData = true;
    }else{
      this.selectedIndex = index;
      this.showAdminData = false;
    }
    this.selectedArrayData = i;
  }


  loadAllVehicle(UserID) {
    const data = {
      userID: UserID
    };
    this.getAllVehicle = this.ownerDashboardService.getAllVehicle(data)
      .subscribe(
        (response) => {
          this.getAllVehicle = response;
        }
      );
  }


  ownerEdit(ownerDetails) {
    this.showOwnerDashForm = false;
    this.closeForm();
    this.loadAllVehicleList(ownerDetails.ownerID);
    this.showOwnerDashForm = true;
    this.ownerid =  ownerDetails.OwnerID;
    this.ownerName =  ownerDetails.ownerName;
    this.ownerPhone =  ownerDetails.PhoneNumber;

    this.ownerdashForm.patchValue({  
      OwnerID: ownerDetails.OwnerID,
      OwnerName:  ownerDetails.ownerName,
    });

  }

  adminValue(value){
    this.adminPerm = value;
  }
  submitAccess(admin){
    const data = {
      vehicleID: admin.Vid,
      userID: admin.UserID,
      permission: this.adminPerm
    };
    this.getVehicleDetails = this.ownerDashboardService.EditAdminAccess(admin.PermissionID,data)
    .subscribe(
      (response) => {
        if (response.status === 'Success') {
          this.successModal.title = 'Admin Access';
          this.successModal.text = 'Admin Access Updated SuccessFully.';
          this.successModal.show();    
          this.loadAllVehicle(this.userDetails[0].userID);
          this.loadAllVehicleList(admin.ownerID);
          this.showOwnerDashForm = false;
          this.showAdminData = false;
          this.ownerdashForm.patchValue({  
            Access:  'Select Access',
          });
        } else {
          this.warningModal.text = 'Cannot update access';
          this.warningModal.show();
        }
      }
    );     
  }

  loadAllVehicleList(ownerID) {
    const data = {
      ownerID: ownerID
    };
    this.getVehicleDetails = this.ownerDashboardService.getVehicleDetails(data)
      .subscribe(
        (response) => {
          this.VehicleListData = response.records;
          this.getAllAdminAccess = response;
        }
      );
  }
  
  
}
