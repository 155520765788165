import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/company',
    title: 'Company',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    showRole: 'SuperAdmin',
    submenu: []
  },
  {
    path: '',
    title: 'Master',
    icon: 'fas fa-street-view',
    class: 'has-arrow',
    extralink: false,
    showRole: 'SuperAdmin',
    submenu: [
      {
        path: '/createowner',
        title: 'Add/Remove Owner',
        icon: 'fas fa-user',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      {
        path: '/category',
        title: 'Category',
        icon: 'fas fa-tags',
        class: '',
        extralink: false,
        showRole: 'SuperAdmin',
        submenu: []
      },
     
      // {
      //   path: '/vehicle',
      //   title: 'Vehicle',
      //   icon: 'fas fa-taxi',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/admin-access',
      //   title: 'Admin Access',
      //   icon: 'fas fa-users',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/transaction',
      //   title: 'Transaction',
      //   icon: 'fas fa-trash-alt',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      {
        path: '/ownerdashboard',
        title: 'Owner Dashboard',
        icon: 'fas fa-clipboard-list',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      {
        path: '/otp',
        title: 'OTP',
        icon: 'fas fa-key',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      // {
      //   path: '/color',
      //   title: 'Color',
      //   icon: 'fas fa-paint-brush',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // // {
      // //   path: '/model',
      // //   title: 'Model',
      // //   icon: ' fab fa-modx',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // // {
      // //   path: '/coating',
      // //   title: 'Coating',
      // //   icon: 'fas fa-pallet',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'SuperAdmin',
      // //   submenu: []
      // // },
      // {
      //   path: '/inclusion',
      //   title: 'Inclusion',
      //   icon: ' fas fa-box',
      //   class: '',
      //   extralink: false,
      //   showRole: 'SuperAdmin',
      //   submenu: []
      // },
      // {
      //   path: '/warrenty',
      //   title: 'Warranty',
      //   icon: ' fas fa-shield-alt',
      //   class: '',
      //   extralink: false,
      //   showRole: 'SuperAdmin',
      //   submenu: []
      // },
      // {
      //   path: '/style',
      //   title: 'Style',
      //   icon: 'fas fa-space-shuttle',
      //   class: '',
      //   extralink: false,
      //   showRole: 'SuperAdmin',
      //   submenu: []
      // },
      // {
      //   path: '/features',
      //   title: 'Features',
      //   icon: ' fas fa-list',
      //   class: '',
      //   extralink: false,
      //   showRole: 'SuperAdmin',
      //   submenu: []
      // },
    ]
  },
  // {
  //   path: '',
  //   title: 'Stock',
  //   icon: 'fas fa-boxes',
  //   class: 'has-arrow',
  //   extralink: false,
  //   showRole: 'SuperAdmin',
  //   submenu: [{
  //     path: '/productmodel',
  //     title: 'ProductModel',
  //     icon: 'fas fa-rocket',
  //     class: '',
  //     extralink: false,
  //     showRole: 'SuperAdmin',
  //     submenu: []
  //   },
  //   // {
  //   //   path: '/eye-lense',
  //   //   title: 'Eye Lens',
  //   //   icon: 'fas fa-space-shuttle',
  //   //   class: '',
  //   //   extralink: false,
  //   //   showRole: 'SuperAdmin',
  //   //   submenu: []
  //   // }
  // ]
  // },
  // SALES
  // {
  //   path: '/Retailor',
  //   title: 'Retailers',
  //   icon: 'fa fa-users',
  //   class: '',
  //   extralink: false,
  //   showRole: 'Sales',
  //   submenu: []
  // },
  // {
  //   path: '',
  //   title: 'Stock',
  //   icon: 'fas fa-boxes',
  //   class: 'has-arrow',
  //   extralink: false,
  //   showRole: 'Sales',
  //   submenu: [{
  //     path: '/productmodel',
  //     title: 'ProductModel',
  //     icon: 'fas fa-rocket',
  //     class: '',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: []
  //   },
  //   {
  //     path: '/eye-lense',
  //     title: 'Eye Lens',
  //     icon: 'fas fa-space-shuttle',
  //     class: '',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: []
  //   }
  // ]
  // },
  //   {
  //     path: '',
  //     title: 'Order',
  //     icon: 'fas fa-fighter-jet',
  //     class: 'has-arrow',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: [
  //     {
  //     path: '/customerorder',
  //     title: 'Customer Order',
  //     icon: 'fas fa-list',
  //     class: '',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: []
  //     },
  //   {
  //     path: '/purchaseorder',
  //     title: 'Purchase Order',
  //     icon: 'fa fa-shopping-basket',
  //     class: '',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: []
  //   },
  //   {
  //     path: '/warehouseorder',
  //     title: 'Warehouse Order',
  //     icon: 'fas fa-warehouse',
  //     class: '',
  //     extralink: false,
  //     showRole: 'Sales',
  //     submenu: []
  //   },
  // ]
  // },
  // {
  //   path: '/urlgenerate',
  //   title: 'Generate URL',
  //   icon: 'fa fa-qrcode',
  //   class: '',
  //   extralink: false,
  //   showRole: 'Sales',
  //   submenu: []
  // },
  // {
  //   path: '/orderListWH',
  //   title: 'Order List',
  //   icon: 'fas fa-truck-moving',
  //   class: '',
  //   extralink: false,
  //   showRole: 'Sales',
  //   submenu: []
  // },

  // OTHERS
  {
    path: '',
    title: 'Master',
    icon: 'fas fa-street-view',
    class: 'has-arrow',
    extralink: false,
    showRole: 'All',
    submenu: [
      {
        path: '/createowner',
        title: 'Add/Remove Owner',
        icon: 'fas fa-user',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      {
        path: '/category',
        title: 'Category',
        icon: 'fas fa-tags',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
     
      // {
      //   path: '/vehicle',
      //   title: 'Vehicle',
      //   icon: 'fas fa-taxi',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/admin-access',
      //   title: 'Admin Access',
      //   icon: 'fas fa-users',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/transaction',
      //   title: 'Transaction',
      //   icon: 'fas fa-trash-alt',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      {
        path: '/ownerdashboard',
        title: 'Owner Dashboard',
        icon: 'fas fa-clipboard-list',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      {
        path: '/otp',
        title: 'OTP',
        icon: 'fas fa-key',
        class: '',
        extralink: false,
        showRole: 'All',
        submenu: []
      },
      // {
      //   path: '/color',
      //   title: 'Color',
      //   icon: 'fas fa-paint-brush',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // // {
      // //   path: '/model',
      // //   title: 'Model',
      // //   icon: ' fab fa-modx',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // // {
      // //   path: '/coating',
      // //   title: 'Coating',
      // //   icon: 'fas fa-pallet',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // {
      //   path: '/staff',
      //   title: 'Staff',
      //   icon: 'fas fa-user',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/inclusion',
      //   title: 'Inclusion',
      //   icon: ' fas fa-box',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/warrenty',
      //   title: 'Warranty',
      //   icon: ' fas fa-shield-alt',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // // {
      // //   path: '/features',
      // //   title: 'Features',
      // //   icon: ' fas fa-list',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // {
      //   path: '/style',
      //   title: 'Style',
      //   icon: 'fas fa-space-shuttle',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // // {
      // //   path: '/lenses',
      // //   title: 'Lenses',
      // //   icon: 'fas fa-object-group',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // // {
      // //   path: '/vendor',
      // //   title: 'Vendor',
      // //   icon: 'fa fa-users',
      // //   class: '',
      // //   extralink: false,
      // //   showRole: 'All',
      // //   submenu: []
      // // },
      // {
      //   path: '/coupon',
      //   title: 'Coupon',
      //   icon: 'fas fa-tags',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/addCustomer',
      //   title: 'Add Customer',
      //   icon: 'fa fa-users',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
      // {
      //   path: '/Retailor',
      //   title: 'Add Retailer',
      //   icon: 'fa fa-users',
      //   class: '',
      //   extralink: false,
      //   showRole: 'All',
      //   submenu: []
      // },
    ]
  },
  // {
  //   path: '',
  //   title: 'Inventory',
  //   icon: 'fas fa-boxes',
  //   class: 'has-arrow',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: [{
  //       path: '/productmodel',
  //       title: 'ProductModel',
  //       icon: 'fas fa-rocket',
  //       class: '',
  //       extralink: false,
  //       showRole: 'All',
  //       submenu: []
  //     },
  //     {
  //       path: '/productbase',
  //       title: 'Product Search',
  //       icon: 'fa fa-search',
  //       class: '',
  //       extralink: false,
  //       showRole: 'All',
  //       submenu: []
  //     },
  //   ]
  // }
      // {
  //     //   path: '/eye-lense',
  //     //   title: 'Eye Lens',
  //     //   icon: 'fas fa-space-shuttle',
  //     //   class: '',
  //     //   extralink: false,
  //     //   showRole: 'All',
  //     //   submenu: []
  //     // }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Order',
  //   icon: 'fas fa-fighter-jet',
  //   class: 'has-arrow',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: [{
  //     path: '/customerorder',
  //     title: 'Customer Order',
  //     icon: 'fas fa-list',
  //     class: '',
  //     extralink: false,
  //     showRole: 'All',
  //     submenu: []
  //   },
  //   {
  //     path: '/purchaseorder',
  //     title: 'Purchase Order',
  //     icon: 'fa fa-shopping-basket',
  //     class: '',
  //     extralink: false,
  //     showRole: 'All',
  //     submenu: []
  //   },
  //   {
  //     path: '/warehouseorder',
  //     title: 'Warehouse Order',
  //     icon: 'fas fa-warehouse',
  //     class: '',
  //     extralink: false,
  //     showRole: 'All',
  //     submenu: []
  //   },
  // ]
  // },

  // {
  //   path: '/productsearch',
  //   title: 'Product Search',
  //   icon: 'fa fa-search',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/urlgenerate',
  //   title: 'Generate URL',
  //   icon: 'fa fa-qrcode',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/listurlgenerate',
  //   title: 'List URL',
  //   icon: 'fas fa-rocket',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/reviewProducts',
  //   title: 'Review Products',
  //   icon: 'fas fa-list',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/barcodescan',
  //   title: 'Scan Barcode',
  //   icon: 'fab fa-schlix',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/productOrder',
  //   title: 'Place Order',
  //   icon: 'fa fa-shopping-basket',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // },
  // {
  //   path: '/orderListWH',
  //   title: 'Order List',
  //   icon: 'fas fa-truck-moving',
  //   class: '',
  //   extralink: false,
  //   showRole: 'All',
  //   submenu: []
  // }
];
