import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  isLoggedin: String = '';

  constructor( private _route: ActivatedRoute) {
    this.isLoggedin = localStorage.getItem('infoRigLoggedIn');
    if (this.isLoggedin !== 'true') {
      window.location.href = '#/authentication/login';
    }else{
      window.location.href = '#/dashboard/classic';
    }
  }
}
