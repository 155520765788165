import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { CreateOwnerService } from './createowner.service';
import { DOMAIN_ADDRESS } from '../const';

@Component({
  selector: 'app-createowner',
  templateUrl: './createowner.component.html',
  styleUrls: ['./createowner.component.css']
})
export class CreateOwnerComponent implements OnInit {
  createownerForm: FormGroup;
  apicall: any;
  getAllCreateOwner: any = '';
  userDetails: any = '';
  showCreateOwnerForm: Boolean = false;
  isNewCreateOwner: Boolean = false;
  existAlert: any = '';
  userID: any = 0;
  companyID: any = '';
  ownerid: any = '';
  countryID: any = '101';
  stateID: any = '';
  cityID: any = '';
  InputArr: any = [];
  stateList: any = [];
  cityList: any = [];
  

  limit: number = 0;
  modelCount: any = 0;
  page: any = 1;
  totalCountOfRecords: any = 0;
  CollectionPageSize: any = 0;
  showPagination:boolean = false;

  // Image
  selectedFile: any;
  getOwnerLogo: any;
  private imageSrc: string = '';

  domainurl: any = DOMAIN_ADDRESS;
  @ViewChild('successModal') private successModal: SwalComponent;
  @ViewChild('warningModal') private warningModal: SwalComponent;
  userType: any;
  constructor(private createOwnerService: CreateOwnerService,
    private formBuilder: FormBuilder) {
    this.createownerForm = new FormGroup({
      UserName: new FormControl(null, [Validators.required]),
      UserID: new FormControl(null),
      OwnerID: new FormControl(null),
      userPhoto: new FormControl(null),
      PhoneNumber: new FormControl(null),
      LoginPin: new FormControl(null),
      EmailID: new FormControl(null),
      OwnerName: new FormControl(null),
      GstNo: new FormControl(null),
      Pincode: new FormControl(null),
      Address: new FormControl(null),
      StateID: new FormControl(null),
      CityID: new FormControl(null),
      CityName: new FormControl(null),
      District: new FormControl(null),
      TelNumber: new FormControl(null),
      NoOfVechile: new FormControl(null),
      VehicleArr: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('infoRig'));    
    this.companyID = 0;
    this.loadAllOwner();
    this.loadGetStateList();
    this.addInputArr();
    this.getOwnerLogo = './assets/images/nouser.png';
  }

  newCreateOwnerForm() {
    this.showCreateOwnerForm = !this.showCreateOwnerForm;
    this.isNewCreateOwner = true;    
    this.createownerForm.reset();
    this.clearFormArray();
    this.loadInputArr();
    this.InputArr = [];
    this.getOwnerLogo = './assets/images/nouser.png';
  }

  closeForm() {
    this.showCreateOwnerForm = false;
    window.scrollTo(0,0);
  }

  onChangeToggle(event,i) {
    if (event.checked) {
      this.createownerForm.patchValue({
        Subscribe : "1",
      }); 
    } else {
      this.createownerForm.patchValue({
        Subscribe : "0",
      }); 
    }
  }

  
   loadAllOwner() {
    let data = {
      Limit: this.limit,
      IsUseLimit: 1
    }
    this.getAllCreateOwner = this.createOwnerService.getAllOwnerList(data);
    this.getAllCreateOwner
    .subscribe(
      (response) => {
        this.getAllCreateOwner = response;
        this.totalCountOfRecords = response.TotalOwnerCount;
        if (this.totalCountOfRecords !== 0 ) {
          this.showPagination = true;
          const LastPage = this.totalCountOfRecords / 10;
          const LP = Math.ceil(LastPage);
          this.CollectionPageSize = LP * 10;
        } else {
          this.showPagination = false;
        }
      }
    );
  }

  onPageChange(page) {
    this.showCreateOwnerForm = false;
    this.limit = (page * 10) - 10;
    this.modelCount = (page * 10) - 10;
    this.loadAllOwner();
  }

  loadGetStateList() {
    const data = {
      countryID : "101"
    };
    this.apicall = this.createOwnerService.getStateList(data);
    this.apicall.subscribe(
      (response) => {
        if (response.record_count > 0) {
          this.stateList = response.records;
        }
      }
    );
  }


  loadGetCityList() {
    const data = {
      StateID: this.stateID
    };
    this.apicall = this.createOwnerService.getCityList(data);
    this.apicall.subscribe(
      (response) => {
        if (response.record_count > 0) {
          this.cityList = response.records;
        }
      }
    );
  }
  
  onStateChange(sTate) {
    this.stateID = sTate;
    this.loadGetCityList();
  }


  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.getOwnerLogo = reader.result;
    this.createownerForm.patchValue({ userPhoto: this.getOwnerLogo });
  }

  ownerEdit(ownerDetails) {
    window.scrollTo(0,0);
    this.showCreateOwnerForm = true;
    this.isNewCreateOwner = false;
    this.ownerid =  ownerDetails.OwnerID;
    this.createownerForm.patchValue({  
      UserID: ownerDetails.UserID,
      OwnerID: ownerDetails.OwnerID,
      userPhoto: ownerDetails.userPhoto,
      UserName:  ownerDetails.UserName,
      PhoneNumber:  ownerDetails.PhoneNumber,
      LoginPin:  ownerDetails.Pin,
      EmailID:  ownerDetails.EmailID,
      OwnerName:  ownerDetails.OwnerName,
      GstNo:  ownerDetails.GstNo,
      StateID:  ownerDetails.StateID,
      CityName:  ownerDetails.CityName,
      CityID:  ownerDetails.CityID,
      Pincode:  ownerDetails.Pincode,
      Address:  ownerDetails.Address,
      TelNumber:  ownerDetails.Telephone,
      NoOfVechile:  ownerDetails.NoOfVechile,
    });
    if (ownerDetails.userPhoto !== '' && ownerDetails.userPhoto != null) {
      this.getOwnerLogo  = this.domainurl + ownerDetails.userPhoto;
    } else {
      this.getOwnerLogo = './assets/images/addimage.png';
    }
    this.clearFormArray();
    if(ownerDetails.VehicleArr != undefined)
    this.setFormArrayValues(ownerDetails.VehicleArr);
  }

  setFormArrayValues(inclusionArr) {
    if (inclusionArr !== '' && inclusionArr !== undefined) {
      inclusionArr.forEach(element => {
        this.InputArr = this.createownerForm.get('VehicleArr') as FormArray;        
        if(element.IsExist == 1)
        this.InputArr.push(this.updateInclusionArr(element));
        else
        this.InputArr.push(this.createInclusionArr());
      });
    }
  }


  ownerDelete(ownerID) {
    const data = {
      OwnerID: ownerID,
      UserID: this.userDetails[0].userID
    };
    this.apicall = this.createOwnerService.deleteOwner(data);
    this.apicall.subscribe(
      (response) => {
        if (response.status === 'Success') {
          this.successModal.title = 'Owner';
          this.successModal.text = 'Owner Deleted SuccessFully.';
          this.successModal.show();
          this.loadAllOwner();
        } else {
            if(response.existOwnerAgentNo != 0 || response.existOwnerBrokerNo != 0 || response.existOwnerCatNo != 0 || response.existOwnerVecNo != 0 || response.existOwnerDocNo != 0 || response.existOwnerFundNo != 0|| response.existOwnerPntNo != 0 || response.existOwnerServiceNo != 0 || response.existOwnerSubNo != 0 || response.existOwnerTnxNo != 0 ){
                this.existAlert = 'Agent, Sub broker, Vehicle, Opening fund, Documentation, Category, Point entry, Service Alert, Transaction, Subscription';
                this.warningModal.text = 'Cannot Delete Owner , Owner Exist in any of '+this.existAlert;
                this.warningModal.show();
            }
        }
      }
    );
  }

   clearFormArray = () => {
    const inclusion = <FormArray>this.createownerForm.controls['VehicleArr'];
        for (let i = inclusion.length - 1; i >= 0; i--) {
          inclusion.removeAt(i);
    }

  }

  submitCreateOwner() {    
    const isUserNameSelected = this.createownerForm.value.UserName;    
    const isPhoneNumberSelected = this.createownerForm.value.PhoneNumber;    
    const isPinSelected = this.createownerForm.value.LoginPin;
    const isEmailIDSelected = this.createownerForm.value.EmailID;
    const isOwnerNameSelected = this.createownerForm.value.OwnerName;
    const isGSTSelected = this.createownerForm.value.GstNo;
    const isStateSelected = this.createownerForm.value.StateID;
    const isCitySelected = this.createownerForm.value.CityID;
    const isPincodeSelected = this.createownerForm.value.Pincode;
    const isAddressSelected = this.createownerForm.value.Address;
    const isTelephoneNumberSelected = this.createownerForm.value.TelNumber;
    const isNoofVehicleSelected = this.createownerForm.value.NoOfVechile; 

    if(this.createownerForm.value.UserName != undefined && this.createownerForm.value.PhoneNumber != null && this.createownerForm.value.LoginPin != null  && this.createownerForm.value.OwnerName != null){
     
                      if (this.createownerForm.value.OwnerID !== null) {
                        this.apicall = this.createOwnerService.updateOwner(this.createownerForm.value);
                        this.apicall.subscribe(
                          (response) => {
                            if (response.status === 'Success') {
                              this.successModal.title = 'Owner';
                              this.successModal.text = 'Owner Updated SuccessFully.';
                            this.successModal.show();
                            this.loadAllOwner();
                            this.showCreateOwnerForm = false;                            
                            this.createownerForm.reset();
                            window.scrollTo(0,0);

                            } else {
                              this.warningModal.text = 'Cannot Update Owner';
                            this.warningModal.show();

                            }
                          }
                        );
                      } else {
                      this.createownerForm.patchValue({
                        UserName: isUserNameSelected,
                        PhoneNumber: isPhoneNumberSelected,
                        LoginPin: isPinSelected,
                        EmailID: isEmailIDSelected,
                        OwnerName: isOwnerNameSelected,
                        GstNo: isGSTSelected,
                        StateID: isStateSelected,
                        CityID: isCitySelected,
                        Pincode: isPincodeSelected,
                        Address: isAddressSelected,
                        TelNumber: isTelephoneNumberSelected,
                        NoOfVechile: isNoofVehicleSelected,
                      }); 
                        this.apicall = this.createOwnerService.createOwner(this.createownerForm.value);
                        this.apicall.subscribe(
                          (response) => {
                            if (response.status === 'success') {
                              this.showCreateOwnerForm = false;
                              this.successModal.show();
                              this.createownerForm.reset();
                              window.scrollTo(0,0);
                              this.loadAllOwner();
                              this.showCreateOwnerForm = false;    
                            } else {
                              this.warningModal.title = 'Exist';
                              this.warningModal.text = response.userName+' user already Exist in '+response.mobile;
                              this.warningModal.show();

                            }
                          }
                        );
                      
                      }
  }
  else {
    window.scrollTo(0,0);
    this.warningModal.text = 'Fill Mandatory fields';
    this.warningModal.show();
  }
  }

  get VehicleArr() {
    return <FormArray>this.createownerForm.get('VehicleArr');
  }

  addInputArr() {
    this.InputArr = this.createownerForm.get('VehicleArr') as FormArray;
    this.InputArr.push(this.createInclusionArr());
  }

  loadInputArr() {
    this.InputArr = this.createownerForm.get('VehicleArr') as FormArray;
    this.InputArr.push(this.createInclusionArr());
  }

  removeInclusionArr(i, vechArr) {
    if (vechArr.IsExist == 1) {
      let data = {
        OwnerID:  this.ownerid,
        SubscriptionID: vechArr.SubscriptionID,
        VehicleID: vechArr.vechileID
      } 
      this.apicall = this.createOwnerService.deleteVehicleArray(data);
      this.apicall.subscribe(
        (response) => {
          if (response.status === 'Success') {
            this.InputArr.removeAt(i);
          } else {
            this.warningModal.text = 'Cannot Delete';
            this.warningModal.show();
          }
        }
      );
    } else {
      this.InputArr.removeAt(i);
    }
  }

  updateInclusionArr(inclusion): FormGroup {
    return this.formBuilder.group({
      vechileID: inclusion.vechileID,
      vechileName: inclusion.vechileName,
      vechileNumber: inclusion.vechileNumber,
      openingFund: inclusion.openingFund,
      Subscribe: inclusion.Subscribe,
      SubscriptionID: inclusion.SubscriptionID,
      SubscriptionStart: inclusion.SubscriptionStart,
      SubscriptionEnd: inclusion.SubscriptionEnd,
      IsExist: inclusion.IsExist,
    });
  }

  createInclusionArr(): FormGroup {
    return this.formBuilder.group({
      vechileName: new FormControl(null),
      vechileNumber: new FormControl(null),
      openingFund: new FormControl(null),
      Subscribe: new FormControl(0),
      SubscriptionStart: new FormControl(null),
      SubscriptionEnd: new FormControl(null),
      IsExist: 0
    });
  }

}
